import { htmlSafe } from '@ember/string';

export const WEBLINK_PLACEHOLDER = 'WEBLINK';
export const UNSUBSCRIBE_LINK_PLACEHOLDER = 'ABMELDELINK';
export const SUBSCRIBE_CONFIRM_LINK_PLACEHOLDER = 'ANMELDEBESTAETIGUNGLINK';
export const IMPRINT_LINK_PLACEHOLDER = 'IMPRESSUMLINK';

export const LINK_PLACEHOLDERS = [
  WEBLINK_PLACEHOLDER,
  UNSUBSCRIBE_LINK_PLACEHOLDER,
  SUBSCRIBE_CONFIRM_LINK_PLACEHOLDER,
  IMPRINT_LINK_PLACEHOLDER
];

export const CAMPAIGN_STATES = {
  DRAFT: 0,
  SCHEDULED: 25,
  SENDING: 75,
  SENT: 100,
  RESENDING: 110,
  RESENT: 120,
  SENDING_ABORTED: 210,
  RESENDING_ABORTED: 220,
  SENDING_FAILED_NO_SUBSCRIBERS: 410,
  SENDING_FAILED_NO_VALID_EMAIL: 420
};

export const PENDING_CAMPAIGN_STATES = [25, 75, 110];
export const SENT_CAMPAIGN_STATES = [100, 120, 210, 220];
export const ABORTED_CAMPAIGN_STATES = [210, 220];
export const FAILED_CAMPAIGN_STATES = [410, 420];

// store events service keys
export const STORE_EVENTS_KEY_DRAFT_CAMPAIGNS = 'campaign-list-draft';
export const STORE_EVENTS_KEY_PENDING_CAMPAIGNS = 'campaign-list-pending';
export const STORE_EVENTS_KEY_FAILED_CAMPAIGNS = 'campaign-list-failed';

export const STORE_EVENTS_KEYS = {
  STORE_EVENTS_KEY_DRAFT_CAMPAIGNS,
  STORE_EVENTS_KEY_PENDING_CAMPAIGNS,
  STORE_EVENTS_KEY_FAILED_CAMPAIGNS
};

const listMimetypes = [
  'text/csv',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
];

export const IMPORT_LIST_MIME_TYPES = listMimetypes;
export const EXCLUSION_LIST_MIME_TYPES = listMimetypes;

const CUSTOM_BLOCKS = [
  'whitepaper',
  'offer',
  'establishments',
  'highlights',
  'quote',
  'news',
  'intro',
  'webinar'
];

export const BLOCK_GROUPS = [
  ['header', 'footer'],
  ['title', 'text'],
  ['button', 'menu', 'cta', 'social_media'],
  ['image', 'image_gallery', 'video'],
  ['image_text', 'two_columns'],
  ['teaser', 'signature'],
  CUSTOM_BLOCKS,
  ['html'],
  ['divider']
];

export const BLOCK_GROUPS_V2 = [
  ['header', 'footer'],
  ['text'],
  ['button', 'menu', 'cta', 'social_media'],
  ['media', 'gallery', 'logo_reel'],
  ['image_text', 'two_columns'],
  ['teaser', 'signature'],
  ['code'],
  ['spacer'],
  ['survey', 'journal', 'incentive_voucher', 'voucher', 'offer']
];

export const APPS_BLOCKS = ['journal_post', 'voucher_coupon', 'content_offer'];

export const ACCEPT_HEADER = { Accept: 'application/vnd.additive+json; version=2' };
export const ACCEPT_HEADER_V1 = { Accept: 'application/vnd.newsletter.backend+json' };

export const SALUTATION_PLACEHOLDER = { '{#NACHNAME#}': 'familyName', '{#VORNAME#}': 'givenName' };

export const SUPPORTED_CAMPAIGN_PLACEHOLDERS = [
  'salutation',
  'email',
  'unsubscribeLink',
  'webLink',
  'impressumLink',
  'confirmLink'
];

export const SUPPORTED_CAMPAIGN_TEXT_PLACEHOLDERS = ['ANREDE', 'EMAIL', 'SALUTATION'];

export const SUPPORTED_CAMPAIGN_LINK_PLACEHOLDERS = [
  'ABMELDELINK',
  'WEBLINK',
  'IMPRESSUMLINK',
  'ANMELDEBESTAETIGUNGLINK',
  'UNSUBSCRIBELINK',
  'WEBLINK',
  'DISCLAIMERLINK',
  'CONFIRMATIONLINK'
];

export const SUBSCRIBER_SOURCE_MANUAL = 0;
export const SUBSCRIBER_SOURCE_IMPORT = 10;
export const SUBSCRIBER_SOURCE_HOTEL_SOFTWARE_IMPORT = 20;
export const SUBSCRIBER_SOURCE_ENQUIRY_IMPORT = 30;
export const SUBSCRIBER_SOURCE_ENQUIRY_IMPORT_ALL = 35;
export const SUBSCRIBER_SOURCE_WEBSITE = 100;
export const SUBSCRIBER_SOURCE_MANAGE_SUBSCRIPTION = 110;

export const SUBSCRIBER_CONFIRMATION_DOUBLE_OPT_IN = 'double_opt_in';
export const SUBSCRIBER_CONFIRMATION_CONFIRM = 'confirm';

export const onboardingData = (intl) => {
  return {
    1: [
      {
        title: intl.t('onboarding.1.title'),
        description: htmlSafe(intl.t('onboarding.1.description')),
        illustration: 'newsletter',
        isFirstStep: true
      },
      {
        title: intl.t('onboarding.2.title'),
        description: htmlSafe(intl.t('onboarding.2.description')),
        illustration: 'step-2',
        theme: 'dark'
      },
      {
        title: intl.t('onboarding.3.title'),
        description: htmlSafe(intl.t('onboarding.3.description')),
        illustration: 'step-3',
        theme: 'blue'
      },
      {
        title: intl.t('onboarding.4.title'),
        description: htmlSafe(intl.t('onboarding.4.description')),
        illustration: 'step-4',
        actionLabel: intl.t('onboarding.actions.go'),
        isLastStep: true
      }
    ]
  };
};

export const NL_COLOR_VARIABLES = [
  'mainBackgroundColor',
  'newsletterBackgroundColor',
  'titleColor',
  'subtitleColor',
  'textColor',
  'buttonBackgroundColor',
  'buttonColor',
  'linkColor',
  'metaColor',
  'placeholderColor'
];
