import Model, { attr } from '@ember-data/model';
import { A } from '@ember/array';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';

export default Model.extend({
  intl: service(),

  sentTotal: attr('number', {
    defaultValue() {
      return 0;
    }
  }),
  subscribersTotal: attr('number'),
  openedTotal: attr('number'),
  clickedTotal: attr('number'),
  openedUniqueTotal: attr('number'),
  clickedUniqueTotal: attr('number'),
  hardBouncedTotal: attr('number'),
  softBouncedTotal: attr('number'),
  unsubscribedTotal: attr('number'),
  spamComplaintsTotal: attr('number'),

  openRate: attr('number'),
  openRateAverage: attr('number'),
  clickRate: attr('number'),
  clickRateAverage: attr('number'),
  clickToOpenRate: attr('number'),

  hasGoogleAnalytics: attr('boolean'),

  googleAnalytics: attr({
    defaultValue() {
      return A();
    }
  }),
  gaType: attr('string'),

  createdAt: attr('string'),
  updatedAt: attr('string'),
  deletedAt: attr('string'),

  campaignId: attr('string'),
  campaignName: attr('string'),
  campaignStatus: attr('number'),
  addressBookName: attr('string'),
  resend: attr('boolean'),
  html: attr('string'),
  version: attr('number'),

  subscriberCount: attr('number'),
  resendSubscriberCount: attr('number'),

  sendAt: attr('date'),
  sentAt: attr('date'),
  resendAt: attr('date'),
  resentAt: attr('date'),

  hasIncentive: attr('boolean'),
  generatedIncentiveCount: attr('number'),

  groupNames: attr({
    defaultValue() {
      return A();
    }
  }),

  languages: attr({
    defaultValue() {
      return A();
    }
  }),

  isGa4: computed('gaType', {
    get() {
      return this.gaType === 'ga4';
    }
  }).readOnly(),

  formattedDescription: computed('addressBookName', 'sentAt', {
    get() {
      const { addressBookName, sentAt } = this;

      const addressBookString = this.intl.t('routes.reports.report.toAddressBook', {
        addressBook: addressBookName
      });
      const sentAtString = sentAt && format(new Date(sentAt), 'dd.MM.yyyy', { locale: deLocale });

      return [addressBookString, sentAtString].join(' - ');
    }
  }).readOnly(),

  /**
   * Formatted string of group names
   *
   * @computed formattedSentAt
   * @type {String}
   * @default null
   */
  formattedGroups: computed('groupNames.[]', {
    get() {
      const { groupNames } = this;

      if (!groupNames || !groupNames.length) {
        return this.intl.t('campaigns.configuration.recipient.noRecipients');
      }

      return groupNames.join(', ');
    }
  }).readOnly(),

  /**
   * Formatted string of language names
   *
   * @computed formattedLanguages
   * @type {String}
   * @default null
   */
  formattedLanguages: computed('languages', {
    get() {
      const { languages } = this;
      if (!languages) {
        return null;
      }

      const languageStrings = languages.map((language) => {
        return this.intl.t(`global.languages.${language}`);
      });

      return languageStrings.join(', ');
    }
  }).readOnly()
});
