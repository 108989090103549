import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (gt this._variationTotalCount 1)}}\n  <div\n    class=\"anl-content-block-variation-switch flex items-center absolute top-0 right-0 bg-blue white font-sm\"\n  >\n    {{#if this.isVisible}}\n      <UiIconButton\n        @class=\"color-inherit\"\n        @icon=\"small-arrow-left\"\n        @onClick={{fn this.changeVariation false}}\n      />\n      {{this._currentVariationIndex}}&nbsp;\n      <span class=\"anl-content-block-variation-switch__variation-total-count\">\n        {{t \"components.anlCampaignConfigurator.ofVariations\" totalCount=this._variationTotalCount}}\n      </span>\n      <UiIconButton\n        @class=\"color-inherit\"\n        @icon=\"small-arrow-right\"\n        @onClick={{fn this.changeVariation true}}\n      />\n    {{/if}}\n  </div>\n{{/if}}", {"contents":"{{#if (gt this._variationTotalCount 1)}}\n  <div\n    class=\"anl-content-block-variation-switch flex items-center absolute top-0 right-0 bg-blue white font-sm\"\n  >\n    {{#if this.isVisible}}\n      <UiIconButton\n        @class=\"color-inherit\"\n        @icon=\"small-arrow-left\"\n        @onClick={{fn this.changeVariation false}}\n      />\n      {{this._currentVariationIndex}}&nbsp;\n      <span class=\"anl-content-block-variation-switch__variation-total-count\">\n        {{t \"components.anlCampaignConfigurator.ofVariations\" totalCount=this._variationTotalCount}}\n      </span>\n      <UiIconButton\n        @class=\"color-inherit\"\n        @icon=\"small-arrow-right\"\n        @onClick={{fn this.changeVariation true}}\n      />\n    {{/if}}\n  </div>\n{{/if}}","moduleName":"additive-newsletter/components/anl-campaign-configurator-v2/content-block-variation-switch.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-campaign-configurator-v2/content-block-variation-switch.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { arg } from 'ember-arg-types';
import { bool, func, number, object } from 'prop-types';
import cloneDeep from 'lodash.clonedeep';

/**
 * Handles the switch of content block variations
 *
 * @module anl-campaign-configurator-v2
 * @class AnlCampaignConfiguratorV2ContentBlockVariationSwitchComponent
 */
export default class AnlCampaignConfiguratorV2ContentBlockVariationSwitchComponent extends Component {
  @service aeSaveStack;

  /**
   * the type of this block
   *
   * @argument type
   * @type {String}
   * @default null
   */
  @arg(object)
  contentBlock = null;

  /**
   * whether the variation switch is visible
   *
   * @argument isVisible
   * @type {Boolean}
   * @default false
   */
  @arg(bool)
  isVisible = false;

  /**
   * the position of this block
   *
   * @argument position
   * @type {Number}
   * @default null
   */
  @arg(number)
  position = null;

  get _currentVariationIndex() {
    return (
      Object.keys(this.contentBlock?.variations || {}).indexOf(
        this.contentBlock?.model.variationIdentifier
      ) + 1
    );
  }

  get _variationTotalCount() {
    return Object.keys(this.contentBlock?.variations || {}).length;
  }

  @arg(func)
  onChange = () => {};

  _getChangedProperties(currentProperties, defaultProperties) {
    const changedPropertyGroups = {};

    currentProperties.forEach((contentGroup) => {
      const variationContentGroup = defaultProperties.find(
        (variationContentGroup) => variationContentGroup.id === contentGroup.id
      );

      if (!variationContentGroup) {
        return;
      }

      const changedProperties = {};
      Object.keys(contentGroup.properties).forEach((propertyKey) => {
        if (
          contentGroup.properties[propertyKey].value !==
            variationContentGroup.properties[propertyKey].value ||
          contentGroup.properties[propertyKey].sync !==
            variationContentGroup.properties[propertyKey].sync
        ) {
          const contentValue = contentGroup.properties[propertyKey].value;
          const variationValue = variationContentGroup.properties[propertyKey].value;
          let isEqual = false;

          // if object, compare all properties
          if (contentValue && typeof contentValue === 'object') {
            isEqual = true;
            Object.keys(contentValue).forEach((key) => {
              if (contentValue[key] !== variationValue[key]) {
                isEqual = false;
                return;
              }
            });
          }

          if (!isEqual) {
            changedProperties[contentGroup.properties[propertyKey].id] = {
              value: contentGroup.properties[propertyKey].value
            };

            if (Object.keys(contentGroup.properties[propertyKey]).indexOf('sync') !== -1) {
              changedProperties[contentGroup.properties[propertyKey].id].sync =
                contentGroup.properties[propertyKey].sync;
            }
          }
        }
      });

      if (Object.keys(changedProperties).length > 0) {
        changedPropertyGroups[contentGroup.id] = changedProperties;
      }
    });

    return changedPropertyGroups;
  }

  _applyChangesToProperties(properties, changes, isContent = false) {
    properties.forEach((propertiesGroup, propertiesGroupIndex) => {
      propertiesGroup.properties.forEach((property, propertyIndex) => {
        if (changes?.[propertiesGroup.id]?.[property.id]) {
          properties[propertiesGroupIndex].properties[propertyIndex].value =
            changes[propertiesGroup.id][property.id].value;
          properties[propertiesGroupIndex].properties[propertyIndex].sync =
            changes[propertiesGroup.id][property.id].sync;
        } else if (isContent) {
          // clear properties if content
          if (
            (this.contentBlock.blockIdentifier === 'code' && property.id === 'code') ||
            (this.contentBlock.blockIdentifier === 'footer' && property.id === 'icons') ||
            (this.contentBlock.blockIdentifier === 'social_media' && property.id === 'channels')
          ) {
            return;
          }

          if (
            [
              'image',
              'medium',
              'journal_post',
              'offer',
              'survey',
              'incentive_voucher',
              'voucher'
            ].indexOf(property.type) >= 0
          ) {
            properties[propertiesGroupIndex].properties[propertyIndex].value = null;
            return;
          }

          if (Array.isArray(property.value)) {
            properties[propertiesGroupIndex].properties[propertyIndex].value = [];
          } else if (property.value && typeof property.value === 'object') {
            const value = property.value;
            Object.keys(value).forEach((key) => {
              value[key] = null;
            });
            properties[propertiesGroupIndex].properties[propertyIndex].value = value;
          } else {
            properties[propertiesGroupIndex].properties[propertyIndex].value = null;
          }
        }
      });
    });
  }

  @action
  changeVariation(isNextVariation = false) {
    if (!this.contentBlock?.variations) {
      return;
    }

    const currentIndex = this._currentVariationIndex - 1;
    const currentVariationKey = Object.keys(this.contentBlock.variations)[currentIndex];
    const currentVariation = this.contentBlock.variations[currentVariationKey];

    let nextIndex = isNextVariation ? currentIndex + 1 : currentIndex - 1;
    if (nextIndex < 0) {
      nextIndex = this._variationTotalCount - 1;
    } else if (nextIndex >= this._variationTotalCount) {
      nextIndex = 0;
    }

    const nextVariationKey = Object.keys(this.contentBlock.variations)[nextIndex];
    const nextVariation = this.contentBlock.variations[nextVariationKey];
    const contentBlockModel = this.contentBlock.model;

    // check for changed content
    const changedContent = this._getChangedProperties(
      contentBlockModel.content,
      currentVariation.content
    );

    const newContent = cloneDeep(nextVariation.content);
    this._applyChangesToProperties(newContent, changedContent, true);

    // check for changed style
    const changedStyle = this._getChangedProperties(
      contentBlockModel.style,
      currentVariation.style
    );
    const newStyle = cloneDeep(nextVariation.style);
    this._applyChangesToProperties(newStyle, changedStyle);

    contentBlockModel.set('content', newContent);
    contentBlockModel.set('style', newStyle);
    contentBlockModel.set('styleBlockId', nextVariation);
    contentBlockModel.set('variationIdentifier', nextVariationKey);
    contentBlockModel.set('name', nextVariation.name);

    this.aeSaveStack.pushChanges(contentBlockModel);
    this.onChange(contentBlockModel, this.position);
  }
}
