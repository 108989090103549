import Model, { attr } from '@ember-data/model';
import { A } from '@ember/array';

export default class TemplateModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('string') styleId;
  @attr('string') styleName;
  @attr('string') senderName;
  @attr('string') senderEmail;
  @attr('string') subject;
  @attr('string') previewText;
  @attr('string') replyTo;
  @attr('string') language;
  @attr('boolean') trackClicks;
  @attr('boolean') trackOpenings;
  @attr('boolean') trackGoogleAnalytics;
  @attr('boolean') resend;
  @attr('string') resendAfterDays;
  @attr('string') resendTime;
  @attr('string') resendSubject;
  @attr('string') addressBookId;
  @attr() addressBook;
  @attr('number') filterId;
  @attr('boolean') hasIncentive;

  @attr({
    defaultValue() {
      return A();
    }
  })
  languages;
  @attr({
    defaultValue() {
      return A();
    }
  })
  groups;
  @attr({
    defaultValue() {
      return A();
    }
  })
  availableLanguages;
}
