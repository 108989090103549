import RESTSerializer from '@ember-data/serializer/rest';

import { isBlank } from '@ember/utils';
import cloneDeep from 'lodash.clonedeep';

export default class CampaignBlockSerializer extends RESTSerializer {
  normalizeResponse(store, primaryModelClass, payload) {
    const campaignBlocks = payload.campaignBlocks
      ? payload.campaignBlocks
      : [payload.campaignBlock];

    // change empty link from array to object
    campaignBlocks.forEach((campaignBlock) => {
      campaignBlock.content?.forEach((contentGroup, contentGroupKey) => {
        contentGroup.properties?.forEach((property, propertyKey) => {
          if (property?.type === 'link' && Array.isArray(property.value)) {
            campaignBlock.content[contentGroupKey].properties[propertyKey].value = {};
          }

          if (
            ['journal_post', 'offer', 'voucher', 'incentive_voucher', 'survey'].indexOf(
              property?.type
            ) >= 0 &&
            Array.isArray(property.value)
          ) {
            campaignBlock.content[contentGroupKey].properties[propertyKey].value = null;
          }

          if (property?.type === 'survey' && property.value?.question) {
            const questionPropertyIndex = campaignBlock.content[
              contentGroupKey
            ].properties.findIndex((property) => property.id === 'question');

            if (
              questionPropertyIndex >= 0 &&
              isBlank(
                campaignBlock.content[contentGroupKey].properties[questionPropertyIndex].value
              )
            ) {
              campaignBlock.content[contentGroupKey].properties[questionPropertyIndex].value =
                property.value.question;
            }
          }

          if (
            property?.type === 'link' &&
            property?.value?.type === 'survey' &&
            property?.value?.url
          ) {
            property.value.url = null;
          }

          if (['image', 'media'].indexOf(property?.type) >= 0) {
            if (Array.isArray(property.value?.transformations)) {
              campaignBlock.content[contentGroupKey].properties[propertyKey].value.transformations =
                {};
            }
          }

          if (['images', 'images_text'].indexOf(property?.type) >= 0) {
            if (Array.isArray(property.value)) {
              campaignBlock.content[contentGroupKey].properties[propertyKey].value =
                property.value.map((image) => {
                  if (!image.transformations || Object.keys(image.transformations).length === 0) {
                    image.transformations = null;
                  }

                  return image;
                });
            }
          }
        });
      });
    });

    return super.normalizeResponse(...arguments);
  }

  serialize() {
    let json = super.serialize(...arguments);

    const jsonCopy = cloneDeep(json);

    jsonCopy.content.forEach((contentGroup, contentGroupKey) => {
      contentGroup.properties?.forEach((property, propertyKey) => {
        if (
          ['journal_post', 'offer', 'incentive_voucher', 'voucher', 'survey'].indexOf(
            property?.type
          ) >= 0
        ) {
          const value = {};
          if (property.value?.id) {
            value.id = property.value.id;
          }
          if (property.value?.language) {
            value.language = property.value.language;
          }

          jsonCopy.content[contentGroupKey].properties[propertyKey].value = value;
        }

        function setHttpProtocolOnLink(link, type) {
          if (!link) {
            return link;
          }

          let url = link[type === 'link' ? 'url' : 'link'];
          if (url && url.indexOf('mailto:') != 0 && url.indexOf('tel:') != 0) {
            if (url.indexOf('http') != 0 && !url.startsWith('{#')) {
              url = `https://${url}`;
            }
          }

          link[type === 'link' ? 'url' : 'link'] = url;
          return link;
        }

        if (['link', 'images', 'images_text'].indexOf(property?.type) >= 0) {
          let value = jsonCopy.content[contentGroupKey].properties[propertyKey].value;
          if (Array.isArray(value)) {
            value = value.map((url) => {
              return setHttpProtocolOnLink(url, property?.type);
            });
          } else {
            value = setHttpProtocolOnLink(value, property?.type);
          }

          jsonCopy.content[contentGroupKey].properties[propertyKey].value = value;
        }

        if (['image', 'medium', 'images', 'images_text'].indexOf(property?.type) >= 0) {
          let value = jsonCopy.content[contentGroupKey].properties[propertyKey].value;
          if (Array.isArray(value)) {
            value = value.map((image) => {
              if (!image.transformations || Object.keys(image.transformations).length === 0) {
                image.transformations = null;
              }

              return image;
            });
          } else if (value) {
            if (!value.transformations || Object.keys(value.transformations).length === 0) {
              value.transformations = null;
            }
          }

          jsonCopy.content[contentGroupKey].properties[propertyKey].value = value;
        }
      });
    });

    jsonCopy.style.forEach((styleGroup, styleGroupKey) => {
      styleGroup.properties?.forEach((property, propertyKey) => {
        if (property?.type === 'number') {
          jsonCopy.style[styleGroupKey].properties[propertyKey].value = parseInt(property.value);
        }
      });
    });

    return jsonCopy;
  }
}
